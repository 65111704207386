import { createAction } from '@reduxjs/toolkit';

import { AuxiliaryPageType } from '../../domain/enums/auxiliaryPageType';
import { IAuxiliaryPageEnabledSwitchModel } from '../../domain/models/auxiliaryPageEnabledSwitchModel';
import { IAuxiliaryPageUpdateContentModel } from '../../domain/models/auxiliaryPageUpdateContentModel';
import { IMagnetometerDatasetCreateModel } from '../../domain/models/magnetometerDatasetCreateModel';
import { IMagnetometerDatasetExportModel } from '../../domain/models/magnetometerDatasetExportModel';
import { IReferenceCreateModel } from '../../domain/models/referenceCreateModel';
import { IReferenceUpdateModel } from '../../domain/models/referenceUpdateModel';
import { IAuditLogRequest } from '../../services/requestModels/auditLogRequest';
import { IBulkExportRequest } from '../../services/requestModels/bulkExportRequest';
import { IAuditLogResponse } from '../../services/responseModels/auditLogResponse';
import { IAuxiliaryPageResponse } from '../../services/responseModels/auxiliaryPageResponse';
import { IAuxiliaryPageStatusResponse } from '../../services/responseModels/auxiliaryPageStatusResponse';
import { IMagnetometerDatasetResponse } from '../../services/responseModels/magnetometerDatasetResponse';

const adminStateCleanup = createAction('ADMIN_STATE_CLEAN_UP');

const wreckExportStarted = createAction<IBulkExportRequest>('WRECK_EXPORT_STARTED');
const wreckExportStateChanged = createAction<boolean>('WRECK_EXPORT_STATE_CHANGED');
const wreckExportCancelled = createAction('WRECK_EXPORT_CANCELLED');

const allObservationsExportStarted = createAction<IBulkExportRequest>('OBSERVATION_EXPORT_STARTED');
const allObservationsExportStateChanged = createAction<boolean>('OBSERVATION_EXPORT_STATE_CHANGED');
const allObservationsExportCancelled = createAction('OBSERVATION_EXPORT_CANCELLED');

const dhyObservationsExportStarted = createAction<IBulkExportRequest>('DHY_OBSERVATION_EXPORT_STARTED');
const dhyObservationsExportStateChanged = createAction<boolean>('DHY_OBSERVATION_EXPORT_STATE_CHANGED');
const dhyObservationsExportCancelled = createAction('DHY_OBSERVATION_EXPORT_CANCELLED');

const rwsObservationsExportStarted = createAction<IBulkExportRequest>('RWS_OBSERVATION_EXPORT_STARTED');
const rwsObservationsExportStateChanged = createAction<boolean>('RWS_OBSERVATION_EXPORT_STATE_CHANGED');
const rwsObservationsExportCancelled = createAction('RWS_OBSERVATION_EXPORT_CANCELLED');

const auditLogRequested = createAction<IAuditLogRequest>('AUDIT_LOG_REQUESTED');
const auditLogFetched = createAction<IAuditLogResponse>('AUDIT_LOG_FETCHED');
const auditLogFetchStateChanged = createAction<boolean>('AUDIT_LOG_FETCH_STATE_CHANGED');

const referenceCreate = createAction<IReferenceCreateModel>('REFERENCE_CREATE');
const referenceUpdate = createAction<IReferenceUpdateModel>('REFERENCE_UPDATE');

const auxiliaryPagesRequested = createAction('AUXILIARY_PAGES_REQUESTED');
const auxiliaryPagesFetched = createAction<IAuxiliaryPageStatusResponse[]>('AUXILIARY_PAGES_FETCHED');
const auxiliaryPageEnabledSwitch = createAction<IAuxiliaryPageEnabledSwitchModel>('AUXILIARY_PAGE_ENABLED_SWITCH');
const auxiliaryPageRequested = createAction<AuxiliaryPageType>('AUXILIARY_PAGE_REQUESTED');
const auxiliaryPageFetched = createAction<IAuxiliaryPageResponse>('AUXILIARY_PAGE_FETCHED');
const auxiliaryPageReset = createAction('AUXILIARY_PAGE_RESET');
const auxiliaryPageContentUpdate = createAction<IAuxiliaryPageUpdateContentModel>('AUXILIARY_PAGE_CONTENT_UPDATE');

const magnetometerDatasetsRequested = createAction('MAGNETOMETER_DATASETS_REQUESTED');
const magnetometerDatasetsFetched = createAction<IMagnetometerDatasetResponse[]>('MAGNETOMETER_DATASETS_FETCHED');
const magnetometerDatasetDelete = createAction<number>('MAGNETOMETER_DATASET_DELETE');
const magnetometerDatasetDeleted = createAction<number>('MAGNETOMETER_DATASET_DELETED');
const magnetometerDatasetCreate = createAction<IMagnetometerDatasetCreateModel>('MAGNETOMETER_DATASET_CREATE');
const magnetometerDatasetExport = createAction<IMagnetometerDatasetExportModel>('MAGNETOMETER_DATASET_EXPORT');

export const adminActions = {
  adminStateCleanup,
  wreckExportStarted,
  wreckExportStateChanged,
  wreckExportCancelled,
  allObservationsExportStarted,
  allObservationsExportStateChanged,
  allObservationsExportCancelled,
  dhyObservationsExportStarted,
  dhyObservationsExportStateChanged,
  dhyObservationsExportCancelled,
  rwsObservationsExportStarted,
  rwsObservationsExportStateChanged,
  rwsObservationsExportCancelled,
  auditLogRequested,
  auditLogFetched,
  auditLogFetchStateChanged,
  referenceCreate,
  referenceUpdate,
  auxiliaryPagesRequested,
  auxiliaryPagesFetched,
  auxiliaryPageEnabledSwitch,
  auxiliaryPageRequested,
  auxiliaryPageFetched,
  auxiliaryPageReset,
  auxiliaryPageContentUpdate,
  magnetometerDatasetsRequested,
  magnetometerDatasetsFetched,
  magnetometerDatasetDelete,
  magnetometerDatasetDeleted,
  magnetometerDatasetCreate,
  magnetometerDatasetExport
};
